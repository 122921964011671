var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "solutions-area section-width ptb-100" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "section-title" }, [
        _c("h2", [_vm._v(_vm._s(_vm.albumTitle))]),
        _c("p", [
          _vm._v(
            " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. "
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "card-columns" },
        [
          _c("CoolLightBox", {
            attrs: { items: _vm.pictures, index: _vm.index, fullScreen: true },
            on: {
              close: function($event) {
                _vm.index = null
              }
            }
          }),
          _vm._l(_vm.pictures, function(image, imageIndex) {
            return _c(
              "div",
              {
                key: imageIndex,
                staticClass: "card act-card-hover",
                on: {
                  click: function($event) {
                    _vm.index = imageIndex
                  }
                }
              },
              [
                _c("img", {
                  staticClass: "card-img-top",
                  attrs: { src: image, alt: "..." }
                })
              ]
            )
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }