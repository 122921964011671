<template>
  <div class="solutions-area section-width ptb-100">
    <div class="container">
      <div class="section-title">
        <h2>{{ albumTitle }}</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum
          suspendisse ultrices gravida.
        </p>
      </div>
      <div class="card-columns">
        <CoolLightBox
          :items="pictures"
          :index="index"
          :fullScreen="true"
          @close="index = null"
        >
        </CoolLightBox>
        <div
          class="card act-card-hover"
          v-for="(image, imageIndex) in pictures"
          :key="imageIndex"
          @click="index = imageIndex"
        >
          <img :src="image" class="card-img-top" alt="..." />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import * as CLE from "assets/plugins/ClickLinkEvent";
const apiUrl = `${process.env.VUE_APP_API_URL}/gallery`;

export default {
  name: "AlbumBody",
  components: {
    CoolLightBox,
  },
  metaInfo() {
    return {
      title: this.albumTitle,
    };
  },
  data() {
    return {
      albumTitle: "",
      albumDetail: {},
      pictures: [],
      index: null,
    };
  },
  mounted() {
    console.log("1234");
    this.getAlbumDetail();
    const albumId = this.$route.params.albumId;
    CLE.addClickLinkCount(
      this,
      "space/" + albumId,
      "gallery",
      "gallery_album_id",
      albumId
    );
  },
  methods: {
    getAlbumDetail() {
      const albumId = this.$route.params.albumId;
      const api = `${apiUrl}/album?gallery_album_id=${albumId}&language_id=${this.$i18n.locale}`;
      this.axios.get(api).then((response) => {
        this.albumDetail = response.data;
        this.pictures = [];
        this.albumTitle = this.albumDetail.album_name[this.$i18n.apiLang];
        this.albumDetail.picture.forEach((element) => {
          this.pictures.push(element.path);
        });
      });
    },
  },
};
</script>

<style>
.card-columns {
  column-count: 4;
}

.act-card-hover {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
  cursor: pointer;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.act-card-hover img {
  transition: 0.5s all ease-in-out;
}

.act-card-hover:hover img {
  transform: scale(1.04, 1.04);
}

@media (max-width: 1200px) {
  /* Extra large devices (large desktops, 1200px and down) */
}

@media (max-width: 992px) {
  /* Large devices (desktops, 992px and down) */
  .card-columns {
    column-count: 3;
  }
}

@media (max-width: 768px) {
  /*  Medium devices (tablets, 768px and down) */
  .card-columns {
    column-count: 2;
  }
}

@media (max-width: 576px) {
  /*  Small devices (landscape phones, 576px and down) */
  .card-columns {
    column-count: 1;
  }
}
</style>
